import {
  CUSTOMER_ASSIGNMENT_SUMMARY,
  CUSTOMER_ASSIGNMENTS_CREATE,
  CUSTOMER_ASSIGNMENTS_LIST,
  CUSTOMER_ASSIGNMENTS_OPTIMIZATION_REPORT,
  CUSTOMER_ASSIGNMENTS_SINGLE,
  CUSTOMER_ASSIGNMENTS_SINGLE_EDIT,
  CUSTOMER_PAGE,
  CUSTOMER_SHIPMENTS,
  CUSTOMER_STATISTICS,
} from '@/types/router'
import type { RouteRecordRaw } from 'vue-router'

const customer: Array<RouteRecordRaw> = [
  {
    path: '/customer',
    name: CUSTOMER_PAGE,
    component: () => import('@/views/Customer/Index.vue'),
    meta: {
      auth: true,
      resource: 'customer.area',
      action: 'manage',
      title: 'Customer',
    },
    children: [
      {
        path: 'assignments',
        component: () => import('@/views/Assignments/Index.vue'),
        children: [
          {
            path: '',
            component: () => import('@/views/Assignments/AssignmentsListPage.vue'),
            name: CUSTOMER_ASSIGNMENTS_LIST,
            meta: {
              title: 'Assignments',
              containerClass: 'container-large',
            },
          },
          {
            path: 'create',
            component: () => import('@/views/Assignments/AssignmentsCreate.vue'),
            name: CUSTOMER_ASSIGNMENTS_CREATE,
            meta: {
              title: 'Create new assigment',
            },
          },
          {
            path: 'edit/:id',
            component: () => import('@/views/Assignments/AssignmentsEdit.vue'),
            name: CUSTOMER_ASSIGNMENTS_SINGLE_EDIT,
            meta: {
              title: 'Edit Single Assignment',
            },
          },
          {
            path: ':id',
            component: () => import('@/views/Assignments/AssignmentsSingle.vue'),
            name: CUSTOMER_ASSIGNMENTS_SINGLE,
            meta: {
              title: 'Assigment',
              containerClass: 'container-fluid',
              hideFooter: true,
            },
          },
          {
            path: ':id/optimization-report',
            component: () => import('@/views/OptimizationReport/Index.vue'),
            name: CUSTOMER_ASSIGNMENTS_OPTIMIZATION_REPORT,
            meta: {
              title: 'Report',
              containerClass: 'container',
              hideFooter: true,
            },
          },
        ],
      },
      {
        path: 'single-package-summary/:id',
        component: () => import('@/views/Assignments/AssignmentSummary.vue'),
        name: CUSTOMER_ASSIGNMENT_SUMMARY,
        meta: {
          title: 'Assignment summary',
          containerClass: 'container',
        },
      },
      {
        path: 'statistics',
        component: () => import('@/views/Statistics/Index.vue'),
        name: CUSTOMER_STATISTICS,
        meta: {
          title: 'Statistics',
          containerClass: 'container-large',
        },
      },
      {
        path: 'shipments',
        component: () => import('@/views/Shipments/Index.vue'),
        name: CUSTOMER_SHIPMENTS,
        meta: {
          title: 'Shipments feed',
          containerClass: 'container-large',
        },
      },
    ],
  },
]

export default customer
