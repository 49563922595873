/* ============
 * Account Transformer
 * ============
 *
 * The transformer for the account.
 */

import Transformer from '@/transformers/Transformer'
import type { ResponseUser, User } from '@/types/Models/User'

export default class UserTransformer extends Transformer {
  /**
   * Method used to transform a fetched account.
   */
  static fetch(user: ResponseUser): User {
    return {
      id: user.id,
      type: user.type,
      clientType: user.clientType,
      firstName: user.firstName,
      fullName: user.fullName,
      lastName: user.lastName,
      email: user.email,
      clientNumber: user.clientNumber,
      phoneNumber: user.phoneNumber,
      contractParty: user.contractParty,
      shortName: user.shortName,
      company: user.company,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
      salesman: user.salesman || {},
      salesmanId: user.salesman?.id || null,
      addresses: user.addresses,
      note: user.note,
      hasCustomPrices: !!user.hasCustomPrices,
      hasCustomPriorities: user.hasCustomPriorities,
      hidePrice: user.hidePrice,
      clientAddress: user.clientAddress,
      senderAddress: user.senderAddress,
      canHasCustomerNumbers: user.canHasCustomerNumbers,
      shipmentNotifications: user.shipmentNotifications,
    }
  }

  /**
   * Method used to transform a send user data.
   */
  static send(user: User) {
    const data = {
      id: user.id,
      type: user.type,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.fullName,
      email: user.email,
      clientNumber: user.clientNumber,
      phoneNumber: user.phoneNumber,
      contractParty: user.contractParty,
      shortName: user.shortName,
      company: user.company,
      note: user.note,
      salesman: user.salesman,
      salesmanId: user.salesmanId,
      senderAddress: user.senderAddress,
      clientAddress: user.clientAddress,
      hidePrice: user.hidePrice,
      canHasCustomerNumbers: user.canHasCustomerNumbers,
      shipmentNotifications: user.shipmentNotifications,
    }
    if (user.password) {
      data.password = user.password
    }
    return data
  }
}
