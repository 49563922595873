export const SHOW = 'SHOW'
export const HIDE = 'HIDE'

const state = {
  loader: {
    name: '',
    show: false,
    data: {},
  },
}

const actions = {
  loaderShow: ({ commit }, payload) => commit(SHOW, payload),
  loaderHide: ({ commit }, payload) => commit(HIDE, payload),
}

const getters = {
  loader: (state) => state.loader,
}

const mutations = {
  [SHOW]: (state, { name, data }) => {
    state.loader = {
      name,
      show: true,
      data,
    }
  },
  [HIDE]: (state) => {
    state.loader = { name: null, show: false, data: {} }
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
