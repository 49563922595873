import Transformer from '@/transformers/Transformer'

export default class IntegrationsTransformer extends Transformer {
  /**
   * Method used to transform a fetched account.
   */
  static fetch(data) {
    return {
      id: data.id || '',
      system: data.system || '',
      user_external_id: data.user_external_id || '',
      details: data.details || {},
    }
  }
}
