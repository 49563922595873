import { SINGLE } from '@/const/AssignmentTypes'
import { CREATED } from '@/const/PackingSlipEventTypes'
import { STAFF } from '@/const/AccountTypes'
import PackingSlipProxy from '@/proxies/PackingSlipProxy'
import UserProxy from '@/proxies/UserProxy'
import UserTransformer from '@/transformers/UserTransformer'

export const SET_PACKING_SLIPS = 'SET_PACKING_SLIPS'
export const SET_PACKING_SLIP = 'SET_PACKING_SLIP'
export const PACKING_SLIPS_UPDATE_CHECKED_ITEMS = 'PACKING_SLIPS_UPDATE_CHECKED_ITEMS'
export const PACKING_SLIPS_CLEAR_CHECKED_ITEMS = 'PACKING_SLIPS_CLEAR_CHECKED_ITEMS'
export const PACKING_SLIPS_SET_QUERY = 'PACKING_SLIPS_SET_QUERY'
export const SET_PACKING_SLIPS_ASSIGNMENT_TYPE = 'SET_PACKING_SLIPS_ASSIGNMENT_TYPE'
export const SET_CUSTOMER_ID = 'SET_CUSTOMER_ID'
export const UPDATE_CHECKED_ASSIGNMENTS = 'UPDATE_CHECKED_ASSIGNMENTS'
export const CLEAR_CHECKED_ASSIGNMENTS = 'CLEAR_CHECKED_ASSIGNMENTS'
export const FETCH_STAFFS = 'FETCH_STAFFS'

const state = {
  assignmentType: 'single',
  packingSlip: {
    downloadUrl: null,
    economyPreview: null,
    id: null,
    priorityPreview: null,
    state: null,
  },
  checkedItems: [],
  checkedAssignments: [],
  packingSlips: [],
  total: 0,
  totalWeight: 0,
  query: null,
  customerId: null,
  staffs: [],
}

const getters = {
  getPackingSlip(state) {
    return state.packingSlip
  },
  getPackingSlips(state) {
    return state.packingSlips
  },
  getTotalPackages(state) {
    return state.total
  },
  getTotalWeights(state) {
    return state.totalWeight
  },
  getCheckedItems(state) {
    return state.checkedItems
  },
  getCheckedAssignments(state) {
    return state.checkedAssignments
  },
  getCustomerId(state) {
    return state.customerId
  },
  getAllStaffs(state) {
    return state.staffs
  },
}

const mutations = {
  [SET_PACKING_SLIP](state, packingSlip) {
    state.packingSlip = packingSlip
  },
  [PACKING_SLIPS_SET_QUERY](state, query) {
    state.query = query
  },
  [SET_PACKING_SLIPS](state, packingSlips) {
    state.packingSlips = packingSlips.data
    state.total = packingSlips.total
    state.totalWeight = packingSlips.totalWeight
  },
  [PACKING_SLIPS_UPDATE_CHECKED_ITEMS](state, checkedItems) {
    state.checkedItems = checkedItems
  },
  [PACKING_SLIPS_CLEAR_CHECKED_ITEMS](state) {
    state.checkedItems = []
  },
  [UPDATE_CHECKED_ASSIGNMENTS](state, checkedAssignments) {
    state.checkedAssignments = checkedAssignments
  },
  [CLEAR_CHECKED_ASSIGNMENTS](state) {
    state.checkedAssignments = []
  },
  [SET_PACKING_SLIPS_ASSIGNMENT_TYPE](state, assignmentType) {
    state.assignmentType = assignmentType
  },
  [SET_CUSTOMER_ID](state, customerId) {
    state.customerId = customerId
  },
  [FETCH_STAFFS](state, staffs) {
    state.staffs = staffs
  },
}

const actions = {
  create: ({ commit }, data) => {
    commit(SET_PACKING_SLIP, {
      downloadUrl: null,
      economyPreview: null,
      id: null,
      priorityPreview: null,
      state: CREATED,
    })
    return new PackingSlipProxy()
      .create({ ...data, assignmentType: SINGLE })
      .catch((e) => {
        console.error(e)
      })
      .then((response) => {
        commit(SET_PACKING_SLIP, {
          downloadUrl: response.data.downloadUrl,
          economyPreview: response.data.economyPreview,
          id: response.data.id,
          priorityPreview: response.data.priorityPreview,
          state: CREATED,
        })
      })
  },
  getPackingSlips: ({ commit }, data = {}) =>
    new PackingSlipProxy({ ...data, assignmentType: SINGLE })
      .all()
      .then((response) => {
        commit(SET_PACKING_SLIPS, response)
      })
      .catch(() => {}),
  deletePackingSlip: ({ commit }, id) =>
    new PackingSlipProxy().destroy(id).then(() => {
      commit(SET_PACKING_SLIP, {
        downloadUrl: null,
        economyPreview: null,
        id: null,
        priorityPreview: null,
        state: null,
      })
    }),
  getPackingSlip: ({ commit }, id) =>
    new PackingSlipProxy().find(id).then((response) => {
      commit(SET_PACKING_SLIP, response.data)
    }),
  removeItems: ({ commit }, { id, items }) =>
    new PackingSlipProxy().removeItems(id, items).then(() => {
      commit(PACKING_SLIPS_UPDATE_CHECKED_ITEMS, [])
    }),
  fetchStaffs: ({ commit }) =>
    new UserProxy({ type: STAFF }).all().then((response) => {
      commit(FETCH_STAFFS, UserTransformer.fetchCollection(response.data))
    }),
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
