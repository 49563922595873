import FAQProxy from '@/proxies/FAQProxy'

export const SET_FAQ_QUESTION = 'faq/SET_FAQ_QUESTION'
export const SET_FAQ_QUESTIONS = 'faq/SET_FAQ_QUESTIONS'

const state = {
  question: {
    question: '',
    answer: '',
  },
  questions: [],
}

const getters = {}

const mutations = {
  [SET_FAQ_QUESTION](state, question) {
    state.question = question
  },
  [SET_FAQ_QUESTIONS](state, questions) {
    state.questions = questions
  },
}

const actions = {
  fetchQuestions: ({ commit }) =>
    new FAQProxy().all().then((response) => {
      commit(SET_FAQ_QUESTIONS, response.data)
    }),
  createQuestion: ({ dispatch }, data) =>
    new FAQProxy().create(data).then(() => {
      dispatch('fetchQuestions')
    }),
  fetchQuestion: ({ commit }, id) =>
    new FAQProxy().find(id).then((response) => {
      commit(SET_FAQ_QUESTION, response.data)
      return response.data
    }),
  deleteQuestion: ({ dispatch }, id) =>
    new FAQProxy().destroy(id).then(() => {
      dispatch('fetchQuestions')
    }),
  updateQuestion: ({ dispatch }, { id, data }) =>
    new FAQProxy().update(id, data).then(() => {
      dispatch('fetchQuestions')
    }),
}
export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
