import { LOGIN_PAGE } from '@/types/router'
import router from '@/router'
import AuthProxy from '@/proxies/AuthProxy'
import Axios from '@/services/Axios'

export const CHECK = 'CHECK'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

const actions = {
  check: ({ commit }) => {
    commit(CHECK)
  },
  login: ({ commit, dispatch, rootGetters }, payload) =>
    new Promise((resolve, reject) => {
      new AuthProxy()
        .login(payload)
        .then((response) => {
          if (response.access_token) {
            commit(LOGIN, response.access_token)
            dispatch('account/me', null, { root: true }).then(() => {
              const pathData = localStorage.getItem('pathToLoadAfterLogin')
              const pathToLoadAfterLogin = pathData ? JSON.parse(pathData) : null
              const isCustomer = rootGetters['account/isCustomer']

              if (pathToLoadAfterLogin !== null && isCustomer && pathToLoadAfterLogin.name.includes('staff')) {
                router
                  .push({
                    name: rootGetters['account/homePage'],
                    params: pathToLoadAfterLogin?.params,
                  })
                  .catch(() => {})
              } else {
                router
                  .push({
                    name: pathToLoadAfterLogin !== null ? pathToLoadAfterLogin?.name : rootGetters['account/homePage'],
                    params: pathToLoadAfterLogin?.params,
                  })
                  .catch(() => {})
              }
              resolve(response)
            })
          }
        })
        .catch((e) => {
          if (!e.errors) {
            if (e.response?.data?.error) {
              e.errors = e.response.data.errors
            } else {
              e.errors = e?.message
            }
          }
          reject(e.errors)
        })
    }),
  logout: ({ commit }) => {
    new AuthProxy().logout().finally(() => {
      localStorage.removeItem('pathToLoadAfterLogin')
      commit(LOGOUT)
      router
        .push({
          name: LOGIN_PAGE,
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated' && !error.message.includes('Navigation cancelled')) {
            console.error(error)
          }
        })
    })
  },
  forgotPassword: (context, payload) => new AuthProxy().forgotPassword(payload),
  createPassword: (context, payload) =>
    new Promise((resolve, reject) => {
      new AuthProxy()
        .createPassword(payload)
        .then((response) => resolve(response))
        .catch((e) => reject(e.errors))
    }),
}

const state = {
  authenticated: false,
}

const getters = {}

const mutations = {
  [CHECK](state) {
    state.authenticated = !!localStorage.getItem('id_token')
    if (state.authenticated) {
      const token = localStorage.getItem('id_token')
      window.Echo.connector.options.auth.headers.Authorization = `Bearer ${token}`
      Axios.defaults.headers.common.Authorization = `Bearer ${token}`
    }
  },

  [LOGIN](state, token) {
    state.authenticated = true
    localStorage.setItem('id_token', token)
    window.Echo.connector.options.auth.headers.Authorization = `Bearer ${token}`
    Axios.defaults.headers.common.Authorization = `Bearer ${token}`
  },

  [LOGOUT](state) {
    state.authenticated = false
    localStorage.removeItem('id_token')
    Axios.defaults.headers.common.Authorization = ''
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
