import * as Sentry from '@sentry/vue'
import router from '@/router'

export default {
  install(app: any) {
    if (!import.meta.env.VITE_APP_SENTRY_DSN) {
      return
    }
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_APP_SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
      tracePropagationTargets: ['localhost', /^\//],
    })
  },
}
