import IntegrationsProxy from '@/proxies/Customer/IntegrationsProxy'
import IntegrationsTransformer from '@/transformers/IntegrationsTransformer'

export const INIT_INTEGRATION = 'INIT_INTEGRATION'

const state = {
  integrations: [],
}

const getters = {
  get(state) {
    return state.integrations
  },
}

const mutations = {
  [INIT_INTEGRATION](state, payload) {
    state.integrations = payload.data
  },
}

const actions = {
  init: (_, { customerId, system }) =>
    new Promise((resolve, reject) => {
      new IntegrationsProxy(customerId)
        .initConnection(system)
        .then((res) => resolve(res))
        .catch((e) => reject(e))
    }),
  fetch: ({ commit }, payload) =>
    new Promise((resolve, reject) => {
      new IntegrationsProxy(payload)
        .all()
        .then((response) => {
          commit(INIT_INTEGRATION, IntegrationsTransformer.fetchCollection(response.data))
          resolve(response)
        })
        .catch((e) => {
          reject(e)
        })
    }),
  destroy: (_, { customerId, integrationId }) =>
    new Promise((resolve, reject) => {
      new IntegrationsProxy(customerId)
        .destroy(integrationId)
        .then(() => resolve())
        .catch((e) => reject(e))
    }),
  connectShopify: (_, { customerId, token }) =>
    new Promise((resolve, reject) => {
      new IntegrationsProxy(customerId)
        .connectShopify(token)
        .then((res) => resolve(res))
        .catch((e) => reject(e))
    }),
  connectOnGoing: (_, { customerId, token, externalId }) =>
    new Promise((resolve, reject) => {
      new IntegrationsProxy(customerId)
        .connectOngoing(token, externalId)
        .then((res) => resolve(res))
        .catch((e) => reject(e))
    }),
  connectWoocommerce: (_, { customerId, credentials, options }) =>
    new Promise((resolve, reject) => {
      new IntegrationsProxy(customerId)
        .connectWoocommerce(credentials, options)
        .then((res) => resolve(res))
        .catch((e) => reject(e))
    }),
  runActions: (_, { customerId, id, action, params }) =>
    new Promise((resolve, reject) => {
      new IntegrationsProxy(customerId)
        .runActions(id, action, params)
        .then((res) => resolve(res))
        .catch((e) => reject(e))
    }),
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
