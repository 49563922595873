import { toRaw } from 'vue'
import { ASSIGNMENT, CUSTOMER, SENDER_ADDRESS } from '@/const/PriceTypes'
import CustomerPriceProxy from '@/proxies/Price/CustomerPriceProxy'
import AssignmentPriceProxy from '@/proxies/Price/AssignmentPriceProxy'
import SenderAddressPriceProxy from '@/proxies/Price/SenderAddressPriceProxy'
import BasePriceProxy from '@/proxies/Price/BasePriceProxy'

export const SET_PRICES = 'SET_PRICES'
export const SET_TYPE = 'SET_TYPE'
export const UPDATE_PRICES = 'UPDATE_BASE_PRICE'
export const UPDATE_WEIGHT_PRICES = 'UPDATE_WEIGHT_PRICES'
export const RESET_CHANGED_STATE = 'RESET_CHANGED_STATE'
export const RESET_STATE = 'RESET_STATE'

const state = {
  changedPrices: [],
  prices: [],
  type: '',
}

const getters = {
  all(state) {
    return state.prices
  },
  changedPrices(state) {
    return state.changedPrices
  },
}

const mutations = {
  [SET_PRICES](state, payload) {
    state.prices = payload
  },
  [SET_TYPE](state, payload) {
    state.type = payload
  },
  [UPDATE_PRICES](state, payload) {
    const updatedPrice = {
      id: payload.id,
      pricePerPiece: payload.pricePerPiece,
      pricePerKg: payload.pricePerKg,
      minPrice: payload.minPrice,
    }
    state.prices = state.prices.map((el) => {
      if (el.id === payload.id) {
        return { ...el, ...updatedPrice }
      }
      return el
    })
    const priceIndex = state.changedPrices.findIndex((price) => price.id === updatedPrice.id)

    if (priceIndex < 0) {
      state.changedPrices.push(updatedPrice)
    } else {
      state.changedPrices[priceIndex] = updatedPrice
    }
  },

  [UPDATE_WEIGHT_PRICES](state, { priceId, priceWeightData }) {
    const priceIndex = state.prices.findIndex((price) => price.id === priceId)
    state.prices[priceIndex].weights = state.prices[priceIndex].weights.map((weight) => {
      if (priceWeightData.id === weight.id) {
        return { ...weight, ...priceWeightData }
      }
      return weight
    })
    state.changedPrices.push({ ...state.prices[priceIndex] })
  },

  [RESET_CHANGED_STATE](state) {
    state.changedPrices = []
  },

  [RESET_STATE](state) {
    state.prices = []
    state.changedPrices = []
    state.type = ''
  },
}

const actions = {
  save: async ({ commit, state }, id) => {
    const { type } = state
    const prices = toRaw(state.prices)
    switch (type) {
      case CUSTOMER:
        await new CustomerPriceProxy().update(id, { prices })
        break
      case ASSIGNMENT:
        await new AssignmentPriceProxy().update(id, { prices })
        break
      case SENDER_ADDRESS:
        await new SenderAddressPriceProxy().update(id, { prices })
        break
      default:
        await new BasePriceProxy().update(id, { prices })
    }
    commit(RESET_CHANGED_STATE)
  },
  updatePrices: ({ commit }, payload) => {
    commit(UPDATE_PRICES, payload)
  },
  updateWeightPrices: ({ commit }, payload) => {
    commit(UPDATE_WEIGHT_PRICES, payload)
  },
  reset: async ({ commit, state }, id) => {
    const { type } = state
    switch (type) {
      case CUSTOMER:
        await new CustomerPriceProxy().destroy(id)
        break
      case ASSIGNMENT:
        await new AssignmentPriceProxy().destroy(id)
        break
      case SENDER_ADDRESS:
        await new SenderAddressPriceProxy().destroy(id)
        break
      default:
        break
    }
    commit(RESET_STATE)
  },
  resetState: ({ commit }) => {
    commit(RESET_STATE)
  },
  fetch: async ({ commit, state }, id = '') => {
    const { type } = state
    let result
    switch (type) {
      case CUSTOMER:
        result = await new CustomerPriceProxy().find(id)
        break
      case ASSIGNMENT:
        result = await new AssignmentPriceProxy().find(id)
        break
      case SENDER_ADDRESS:
        result = await new SenderAddressPriceProxy().find(id)
        break
      default:
        result = await new BasePriceProxy().all()
    }
    commit(SET_PRICES, result.data)
    return result.data
  },
  fetchDefault: async ({ state }, id = '') => {
    const { type } = state
    let result
    switch (type) {
      case CUSTOMER:
        result = await new CustomerPriceProxy().getDefaultPrices(id)
        break
      case ASSIGNMENT:
        result = await new AssignmentPriceProxy().getDefaultPrices(id)
        break
      case SENDER_ADDRESS:
        result = await new SenderAddressPriceProxy().getDefaultPrices(id)
        break
      default:
        result = await new BasePriceProxy().all()
    }
    return result.data
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
