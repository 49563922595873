import AssignmentProxy from '@/proxies/AssignmentProxy'

export const FETCH_REPORT_DATA = 'SET_REPORT_DATA'

const state = {
  report: {
    manual: [],
    optimization: [],
    manualTotal: 0,
    percentSaved: 0,
    averageSavingsPerPackage: 0,
    info: {},
    totalSavings: 0,
    optimizationCost: 0,
  },
}

const getters = {
  all(state) {
    return state.report
  },
}

const mutations = {
  [FETCH_REPORT_DATA](state, data) {
    state.report.manual = data.manual
    state.report.optimization = data.optimization
    state.report.manualTotal = data.manualTotal
    state.report.totalSavings = data.totalSavings
    state.report.percentSaved = data.percentSaved
    state.report.averageSavingsPerPackage = data.averageSavingsPerPackage
    state.report.info = data.info
    state.report.optimizationCost = data.optimizationCost
  },
}

const actions = {
  fetch: ({ commit }, payload) => {
    new AssignmentProxy().getReportData(payload).then((response) => {
      commit(FETCH_REPORT_DATA, response.data)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
