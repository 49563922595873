import { AbilityBuilder, PureAbility } from '@casl/ability'

export const defineAbilitiesFor = (account: any) => {
  const { can, build } = new AbilityBuilder(PureAbility)

  if (account && account.type === 'staff') {
    can('manage', 'staff.area')
    can('manage', 'prices')
    can('manage', 'proxy')
    can('manage', 'pickupDate')
    can('view', 'pickups-list')
    can('view', 'shipments')
    can('view', 'assignmentsList')
    can('create', 'assigment')
    can('create', 'customer')
    can('manage', 'assigment')
    can('manage', 'single-parcel-assigment')
    can('manage', 'customer')
    can('manage', 'faq')
  } else if (account && account.type === 'customer') {
    can('manage', 'customer.area')
    can('create', 'assigment')
    can('manage', 'assigment')
    can('manage', 'single-parcel-assigment')
    can('view', 'shipments')
    can('view', 'assignmentsList')
  }

  return build()
}
