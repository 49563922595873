import Transformer from '@/transformers/Transformer'
import type { ItemData } from '@/types/Models/Item'

export default class ItemTransformer extends Transformer {
  /**
   * Method used to transform a fetched Assignment's Item.
   */
  static fetch(item: any): ItemData {
    if (!item) {
      return {}
    }
    const itemData = {
      id: item.id,
      assignment: item.assignment,
      user: item.user,
      reference: item.reference,
      delivery_type: item.deliveryType,
      customerReference: item.customerReference,
      orderReference: item.orderReference,
      labelsCreationStatus: item.labelsCreationStatus,
      carrierHandle: item.carrierHandle,
      carrierName: item.carrierName,
      shipmentNumber: item.shipmentNumber,
      labelUrl: item.labelUrl,
      description: item.description,
      shippingError: item.shippingError,
      trackingEvents: item.trackingEvents,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      isEU: item.isEU || false,
      currency: item.currency,
      company_name: item.company_name,
      lastTrackingEventStatus: item.lastTrackingEventStatus,
      lastTrackingEventTime: item.lastTrackingEventTime,
      lastTrackingEventDescription: item.lastTrackingEventDescription,
      sourceFile: item.sourceFile || {},
      weight: parseInt(item.weight, 10),
      width: parseInt(item.width, 10),
      height: parseInt(item.height, 10),
      length: parseInt(item.length, 10),
      estimatedCost: parseInt(item.estimatedCost || 0, 10),
      recipientAddress: item.recipientAddress || {},
      products: item.products || [],
    }
    if (Array.isArray(item.validationFlags)) {
      itemData.validationFlags =
        item.validationFlags?.reduce((acc, cv) => {
          acc[cv.field] = { level: cv.level, reason: cv.reason }
          return acc
        }, {}) || {}
    }
    if (itemData.products.length) {
      ;[itemData.product] = item.products
    }
    if (item.invoice?.products?.length) {
      itemData.invoiceType = item.invoice.type
    }
    if (item.addresses?.length) {
      ;[itemData.recipientAddress] = item.addresses.filter((address) => address.type === 'recipient')
      ;[itemData.senderAddress] = item.addresses.filter((address) => address.type === 'sender')
    }

    return { item, ...itemData }
  }

  static create(item) {
    const itemData = { ...item }
    delete itemData.parcel_characteristics

    itemData.estimated_cost = item.parcel_characteristics.reduce((acc, cv) => acc + +cv.estimated_cost, 0)
    itemData.weight = item.parcel_characteristics.reduce((acc, cv) => acc + +cv.weight, 0)
    itemData.height = item.parcel_characteristics.reduce((acc, cv) => acc + +cv.height, 0)
    itemData.length = item.parcel_characteristics.reduce((acc, cv) => acc + +cv.length, 0)
    itemData.width = item.parcel_characteristics.reduce((acc, cv) => acc + +cv.width, 0)
    itemData.products = item.parcel_characteristics.map((product) => ({
      weight: product.weight * 1,
      width: product.width * 1,
      height: product.height * 1,
      length: product.length * 1,
      estimatedCost: product.estimated_cost * 1,
      description: product.description,
      commodityCode: product.commodity_code,
      quantity: 1,
    }))
    if (item.parcel_characteristics.length > 0 && item.parcel_characteristics[0].description) {
      itemData.reference = item.parcel_characteristics[0].description
    }

    return itemData
  }
}
