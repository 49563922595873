import { findModal } from '@/services/Modal'

const state = {
  modalProps: {},
}

const mutations = {
  setModalProps(state: any, { name, data }: any) {
    state.modalProps = {
      ...state.modalProps,
      [name]: data,
    }
  },
}

const actions = {
  openModal({ commit }: any, { name, data }: any) {
    commit('setModalProps', { name, data })
    findModal(name)?.show()
  },
  closeModal({ commit }: any, name: string) {
    commit('setModalProps', { name, data: {} })
    findModal(name)?.hide()
  },
}

const getters = {
  modalProps: (state: any) => (name: string) => state.modalProps[name],
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
