export const ASENDIA = 'asendia'
export const CITYMAIL = 'citymail'
export const LETTER_CARRIER = 'letter_carrier'
export const DEUTSCHEPOST = 'deutschepost'
export const DHL_EXPRESS = 'dhl_express'
export const DHL_FREIGHT = 'dhl_freight'
export const EARLYBIRD = 'earlybird'
export const POSTNORD = 'postnord'
export const BRING = 'bring'
export const UPS = 'ups'

export const CARRIERS = {
  ASENDIA,
  CITYMAIL,
  DEUTSCHEPOST,
  DHL_EXPRESS,
  DHL_FREIGHT,
  EARLYBIRD,
  POSTNORD,
  BRING,
  UPS,
  LETTER_CARRIER,
}

export const CARRIERS_NAMES = {
  [DHL_EXPRESS]: 'DHL Express',
  [DHL_FREIGHT]: 'DHL Freight',
  [LETTER_CARRIER]: 'Mail',
  [POSTNORD]: 'Postnord',
  [ASENDIA]: 'Asendia',
  [CITYMAIL]: 'Citymail',
  [DEUTSCHEPOST]: 'Deutsche Post',
  [EARLYBIRD]: 'Earlybird',
  [BRING]: 'Bring',
  [UPS]: 'UPS',
}
