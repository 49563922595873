export const PENDING = 'pending'
export const VALIDATING = 'validating'
export const VALIDATION_COMPLETE = 'validation_complete'
export const IN_REVIEW = 'in_review'
export const OPTIMISING = 'optimising'
export const OPTIMISATION_COMPLETE = 'optimisation_complete'
export const REPORT_RELEASED = 'report_released'
export const REPORT_ACCEPTED = 'report_accepted'
export const REPORT_DENIED = 'report_denied'
export const LABELS_CREATING = 'labels_creating'
export const LABELS_CREATED = 'labels_created'
export const LABELS_RELEASED = 'labels_released'
export const CANCELLED = 'cancelled'
export const COMPLETE = 'complete'
export const LABELS_DOWNLOAD_PREPARING = 'labels_download_preparing'
export const LABELS_DOWNLOAD_READY = 'labels_download_ready'

export const STAFF_EDITABLE_STATUSES = [IN_REVIEW, VALIDATION_COMPLETE, REPORT_DENIED]

export const CUSTOMER_EDITABLE_STATUSES = [VALIDATION_COMPLETE, REPORT_DENIED]

export const STAFF_LABEL_READY_FOR_DOWNLOAD_STATUSES = [LABELS_CREATED, LABELS_RELEASED]

export const CUSTOMER_LABEL_READY_FOR_DOWNLOAD_STATUSES = [LABELS_CREATED, LABELS_RELEASED]

export const STAFF_TRACKING_NUMBER_READY = [
  LABELS_CREATED,
  LABELS_RELEASED,
  CANCELLED,
  COMPLETE,
  LABELS_DOWNLOAD_PREPARING,
  LABELS_DOWNLOAD_READY,
]

export const CUSTOMER_TRACKING_NUMBER_READY = [
  LABELS_RELEASED,
  CANCELLED,
  COMPLETE,
  LABELS_DOWNLOAD_PREPARING,
  LABELS_DOWNLOAD_READY,
]

export const BACK_TO_EDIT_AVAILABLE = [
  LABELS_RELEASED,
  LABELS_CREATED,
  OPTIMISATION_COMPLETE,
  REPORT_RELEASED,
  REPORT_ACCEPTED,
]

export default {
  PENDING,
  VALIDATING,
  VALIDATION_COMPLETE,
  IN_REVIEW,
  OPTIMISING,
  OPTIMISATION_COMPLETE,
  REPORT_RELEASED,
  REPORT_ACCEPTED,
  REPORT_DENIED,
  LABELS_CREATING,
  LABELS_CREATED,
  LABELS_RELEASED,
  CANCELLED,
  COMPLETE,
}
