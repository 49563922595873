import { applyProxyMethodsMixin, ProxyMethods } from '@/proxies/Proxy'

export default class PackingSlipProxy extends applyProxyMethodsMixin(class {}) implements ProxyMethods {
  public endpoint = 'packing-slips'

  getItems(id: string) {
    return this.submit('get', `/${this.endpoint}/${id}/items`)
  }

  removeItems(id: string, items: string[]) {
    return this.submit('post', `/${this.endpoint}/${id}/remove-items`, {
      items,
    })
  }
}
