/* ============
 * Main File
 * ============
 *
 * Will initialize the application.
 */

import { createApp } from 'vue'
import { createBootstrap } from 'bootstrap-vue-next'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import Notifications from '@kyvg/vue3-notification'
import vClickOutside from 'click-outside-vue3'

// styles
import 'font-awesome/css/font-awesome.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import 'vue3-easy-data-table/dist/style.css'
import 'vue-select/dist/vue-select.css'
import '@/assets/scss/main.scss'

// Plugins
import '@/plugins/vee-validate'
import '@/plugins/pusher'
import Sentry from '@/plugins/sentry'
import MDIcons from '@/plugins/md-icons-vue'
import VueClipboard from '@/plugins/vue-clipboard'

const app = createApp(App)

app.use(createBootstrap())
app.use(Notifications)
app.use(vClickOutside)
app.use(VueClipboard)
app.use(MDIcons)
app.use(Sentry)
app.use(router)
app.use(store)
;(async () => {
  await store.dispatch('auth/check')
  app.mount('#app')
})()
