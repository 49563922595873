import Axios from '@/services/Axios'
import type { ResponseData } from '@/types/Components/Response'

export interface ProxyMethods {
  submit(requestType: string, url: string, data?: object | null, options?: object): Promise<any>
  all(): Promise<ResponseData>
  find(id: string | number): Promise<any>
  create(item: object | FormData, options?: object): Promise<any>
  update(id: string | number, item: object): Promise<any>
  updateField(id: string, field: string, value: any): Promise<any>
  destroy(id: string): Promise<any>
  endpoint: string
}

export function applyProxyMethodsMixin<T extends new (...args: any[]) => object>(Base: T) {
  return class extends Base implements ProxyMethods {
    public endpoint!: string
    public parameters: object

    constructor(params?: object = {}) {
      super(params)
      this.parameters = params
    }

    submit(requestType: string, url: string, data: object | null = null, options: object = {}): Promise<any> {
      const http = Axios
      const clearedUrl = url.replace(/\/$/, '')
      return new Promise((resolve, reject) => {
        http[requestType](clearedUrl + this.getParameterString(), data, options)
          .then((response) => {
            resolve(response.data)
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data)
            } else {
              reject(e)
            }
          })
      })
    }

    getParameterString(): string {
      const params = { ...this.parameters }
      const keys = Object.keys(params)
      const parameterStrings = keys
        .filter((key) => !!params[key] || params[key] === 0)
        .map((key) => `${key}=${params[key]}`)

      return parameterStrings.length === 0 ? '' : `?${parameterStrings.join('&')}`
    }

    all(): Promise<ResponseData> {
      return this.submit('get', `/${this.endpoint}`)
    }

    find(id: string | number): Promise<any> {
      return this.submit('get', `/${this.endpoint}/${id}`)
    }

    create(item: object | FormData, options: object = {}): Promise<any> {
      return this.submit('post', `/${this.endpoint}`, item, options)
    }

    update(id: string | number, item: object): Promise<any> {
      return this.submit('put', `/${this.endpoint}/${id}`, item)
    }

    updateField(id: string, field: string, value: any): Promise<any> {
      const data = { [field]: value }
      return this.submit('patch', `/${this.endpoint}/${id}`, data)
    }

    destroy(id: string): Promise<any> {
      return this.submit('delete', `/${this.endpoint}/${id}`)
    }
  }
}
