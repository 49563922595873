import SenderAddressProxy from '@/proxies/Customer/SenderAddressProxy'
import SenderAddressTransformer from '@/transformers/SenderAddressTransformer'

export const FILL_SENDER_ADDRESSES = 'FILL_SENDER_ADDRESSES'
export const RESET_SENDER_ADDRESSES = 'RESET_SENDER_ADDRESSES'
export const RESET_SELECTED_SENDER_ADDRESS = 'RESET_SELECTED_SENDER_ADDRESS'
export const FILL_SENDER_ADDRESS = 'FILL_SENDER_ADDRESS'

const state = {
  senderAddresses: [],
  selectedSenderAddress: {},
}

const getters = {
  get(state) {
    return state.senderAddresses
  },
  getSelectedSenderAddress(state) {
    return JSON.parse(JSON.stringify(state.selectedSenderAddress))
  },
}

const mutations = {
  [FILL_SENDER_ADDRESSES](state, payload) {
    state.senderAddresses = payload
  },
  [RESET_SENDER_ADDRESSES](state) {
    state.senderAddresses = []
  },
  [RESET_SELECTED_SENDER_ADDRESS](state) {
    state.selectedSenderADdress = {}
  },
  [FILL_SENDER_ADDRESS](state, payload) {
    state.selectedSenderAddress = payload
  },
  [RESET_SELECTED_SENDER_ADDRESS](state) {
    state.selectedSenderAddress = {}
  },
}

const actions = {
  store: (_, { customerId, data }) => new SenderAddressProxy(customerId).create(data),
  fetch: ({ commit }, payload) =>
    new Promise((resolve, reject) => {
      new SenderAddressProxy(payload)
        .all()
        .then((response) => {
          commit(FILL_SENDER_ADDRESSES, SenderAddressTransformer.fetchCollection(response.data))
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    }),
  resetSenderAddresses: ({ commit }) => {
    commit(RESET_SENDER_ADDRESSES)
  },
  selectSenderAddress: ({ commit }, { customerId, addressId }) =>
    new Promise((resolve, reject) => {
      new SenderAddressProxy(customerId)
        .getSenderAddress(addressId)
        .then((res) => {
          commit(FILL_SENDER_ADDRESS, res.data)
          resolve()
        })
        .catch((e) => reject(e))
    }),
  updateSenderAddress: (_, { customerId, addressId, sender }) =>
    new Promise((resolve, reject) => {
      new SenderAddressProxy(customerId)
        .update(addressId, sender)
        .then((res) => resolve(res))
        .catch((e) => reject(e))
    }),
  destroySenderAddress: (_, { customerId, addressId }) =>
    new Promise((resolve, reject) => {
      new SenderAddressProxy(customerId)
        .destroy(addressId)
        .then(() => resolve())
        .catch((e) => reject(e))
    }),
  setAsDefaultSenderAddress: (_, { customerId, addressId }) =>
    new Promise((resolve, reject) => {
      new SenderAddressProxy(customerId)
        .setDefaultSenderAddress(addressId)
        .then(() => resolve())
        .catch((e) => reject(e))
    }),
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
