/* ============
 * Customers Module
 * ============
 */

import integrations from '@/store/modules/customers/integrations'
import senderAddresses from '@/store/modules/customers/senderAddresses'
import UserProxy from '@/proxies/UserProxy'
import UserTransformer from '@/transformers/UserTransformer'
import ApiProxy from '@/proxies/ApiProxy'
import { CUSTOMER } from '@/const/AccountTypes'

export const FILL_CUSTOMER = 'FILL_CUSTOMER'
export const FILL_CUSTOMERS = 'FILL_CUSTOMERS'

const state = {
  customer: {
    email: null,
    clientNumber: null,
    firstName: null,
    lastName: null,
    company: null,
    clientAddress: {
      countryCode: null,
      city: null,
      zip: null,
      address_line: null,
      address_line2: null,
    },
    senderAddress: {
      fullName: null,
      countryCode: null,
      city: null,
      zip: null,
      address_line: null,
      address_line2: null,
    },
    phoneNumber: null,
    shortName: null,
    contractParty: null,
    assignedSalesman: null,
    clientType: null,
    hidePrice: null,
  },
  customers: [],
}

const getters = {
  get: (state) => state.customer,
  all: (state) => state.customers,
}

const mutations = {
  [FILL_CUSTOMER](state, payload) {
    Object.assign(state.customer, payload)
  },
  [FILL_CUSTOMERS](state, payload) {
    const customersList = payload
    Object.keys(customersList).forEach((key) => {
      customersList[key].label =
        customersList[key].company || customersList[key].fullName.length > 1 || customersList[key].email
      customersList[key].name = customersList[key].fullName || customersList[key].email
      customersList[key].handle = customersList[key].id

      customersList[key].status = 'active'
    })
    state.customers = customersList
  },
}

const actions = {
  create: (_, payload) => new UserProxy().create(payload),
  archive: (_, payload) => new UserProxy().updateField(payload, 'is_active', 0),
  unarchive: (_, payload) => new UserProxy().updateField(payload, 'is_active', 1),
  update: (_, { id, data }) => new UserProxy().update(id, UserTransformer.send(data)),
  requestCreate: (_, payload) => new ApiProxy().requestCreate(payload),
  fetch: ({ commit }, payload) => {
    new UserProxy().find(payload).then((response) => {
      commit(FILL_CUSTOMER, response.data)
    })
  },
  fetchAll: ({ commit }) => {
    new UserProxy({ type: CUSTOMER })
      .all()
      .then((response) => {
        commit(FILL_CUSTOMERS, response.data || [])
      })
      .catch(() => {})
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
  modules: {
    integrations,
    senderAddresses,
  },
}
