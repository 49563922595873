import { applyProxyMethodsMixin, ProxyMethods } from '@/proxies/Proxy'

export default class AuthProxy extends applyProxyMethodsMixin(class {}) implements ProxyMethods {
  public endpoint = 'auth'

  login({ email, password }) {
    const data = {
      email,
      password,
    }
    return this.submit('post', `${this.endpoint}/login`, data)
  }

  logout() {
    return this.submit('post', `${this.endpoint}/logout`)
  }

  forgotPassword(data) {
    return this.submit('post', `${this.endpoint}/forgot-password`, data)
  }

  createPassword(data) {
    return this.submit('post', `${this.endpoint}/reset-password`, data)
  }
}
