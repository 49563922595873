// Desc: Priority module for vuex store
import { unionBy } from '@/services/Utils'
import { ASSIGNMENT, CUSTOMER, SENDER_ADDRESS } from '@/const/PriorityTypes'
import CustomerPriorityProxy from '@/proxies/Priority/CustomerPriorityProxy'
import AssignmentPriorityProxy from '@/proxies/Priority/AssignmentPriorityProxy'
import SenderAddressPriorityProxy from '@/proxies/Priority/SenderAddressPriorityProxy'
import BasePriorityProxy from '@/proxies/Priority/BasePriorityProxy'

export const SET_PRIORITIES = 'SET_PRIORITIES'
export const UPDATE_PRIORITIES = 'UPDATE_PRIORITIES'
export const RESET_PRIORITIES = 'RESET_PRIORITIES'
export const RESET_STATE = 'RESET_STATE'
export const SET_TYPE = 'SET_TYPE'

const state = {
  priorities: [],
  changed: [],
  type: '',
}

const getters = {
  all(state) {
    return state.priorities
  },
  changed(state) {
    return state.changed
  },
}

const mutations = {
  [SET_PRIORITIES](state, payload) {
    state.priorities = payload
  },
  [UPDATE_PRIORITIES](state, payload) {
    state.priorities = unionBy(payload, state.priorities, 'id')
    state.changed = unionBy(payload, state.changed, 'id')
  },
  [RESET_PRIORITIES](state) {
    state.changedPriorities = []
  },
  [RESET_STATE](state) {
    state.changed = []
    state.priorities = []
    state.type = ''
  },
  [SET_TYPE](state, payload) {
    state.type = payload
  },
}

const actions = {
  set: ({ commit }, payload) => {
    commit(UPDATE_PRIORITIES, payload)
  },
  reset: async ({ commit, state }, id) => {
    const { type } = state
    switch (type) {
      case CUSTOMER:
        await new CustomerPriorityProxy().destroy(id)
        break
      case ASSIGNMENT:
        await new AssignmentPriorityProxy().destroy(id)
        break
      case SENDER_ADDRESS:
        await new SenderAddressPriorityProxy().destroy(id)
        break
      default:
        break
    }
    commit(RESET_STATE)
  },
  fetch: async ({ commit, state }, id = '') => {
    const { type } = state
    let result
    switch (type) {
      case CUSTOMER:
        result = await new CustomerPriorityProxy().find(id)
        break
      case ASSIGNMENT:
        result = await new AssignmentPriorityProxy().find(id)
        break
      case SENDER_ADDRESS:
        result = await new SenderAddressPriorityProxy().find(id)
        break
      default:
        result = await new BasePriorityProxy().all()
    }
    commit(SET_PRIORITIES, result.data)
    return result.data
  },
  save: async ({ commit, state }, id) => {
    const { type, priorities } = state

    switch (type) {
      case CUSTOMER:
        await new CustomerPriorityProxy().update(id, { priorities })
        break
      case ASSIGNMENT:
        await new AssignmentPriorityProxy().update(id, { priorities })
        break
      case SENDER_ADDRESS:
        await new SenderAddressPriorityProxy().update(id, { priorities })
        break
      default:
        await new BasePriorityProxy().update(id, { priorities })
    }
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
