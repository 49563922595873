/* ============
 * Vuex Store
 * ============
 *
 * The store of the application.
 *
 * http://vuex.vuejs.org/en/index.html
 */

import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

// Modules
import account from '@/store/modules/account'
import auth from '@/store/modules/auth'
import customers from '@/store/modules/customers'
import assignment from '@/store/modules/assignment'
import prices from '@/store/modules/prices'
import priority from '@/store/modules/priority'
import item from '@/store/modules/item'
import ui from '@/store/modules/ui'
import faq from '@/store/modules/faq'
import packingSlip from '@/store/modules/packingSlip'
import vueNextModal from '@/store/modules/vueNextModal'

// Plugins
import { defineAbilitiesFor } from '@/services/CaslAbility'

const debug = import.meta.env.VITE_ENV !== 'production'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    account: state.account,
    auth: state.auth,
    staff: state.staff,
  }),
})
const store = createStore({
  /**
   * Assign the modules to the store.
   */
  modules: {
    ui,
    account,
    auth,
    customers,
    assignment,
    prices,
    priority,
    item,
    faq,
    packingSlip,
    vueNextModal,
  },

  /**
   * If strict mode should be enabled.
   */
  strict: debug,
  getters: {
    isLoggedIn(state) {
      return state.auth.authenticated
    },
    ability(state) {
      const curAccount = state.account
      return defineAbilitiesFor(curAccount)
    },
  },

  /**
   * Plugins used in the store.
   */
  plugins: [vuexLocal.plugin],
})

export default store
