import type { RouteRecordRaw } from 'vue-router'
import {
  CUSTOMER_CREATE_PASSWORD,
  CUSTOMER_FORGOT_PASSWORD,
  CUSTOMER_RESET_PASSWORD,
  LOGIN_PAGE,
  REGISTRATION,
} from '@/types/router'
import store from '@/store'

const authRoutes: Array<RouteRecordRaw> = [
  {
    path: '/forgot-password',
    component: () => import('@/views/Account/ForgotPass.vue'),
    name: CUSTOMER_FORGOT_PASSWORD,
    meta: {
      title: 'Forgot Password',
    },
  },
  {
    path: '/create-password/',
    component: () => import('@/views/Account/CreatePass.vue'),
    name: CUSTOMER_CREATE_PASSWORD,
    meta: {
      title: 'Create Password',
    },
  },
  {
    path: '/reset-password/',
    component: () => import('@/views/Account/ResetPass.vue'),
    name: CUSTOMER_RESET_PASSWORD,
    meta: {
      title: 'Reset Password',
    },
  },
  {
    path: '/',
    name: LOGIN_PAGE,
    component: () => import('@/views/Account/Login.vue'),
    meta: {
      guest: true,
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['isLoggedIn']) {
        next()
      } else {
        next({ name: store.getters['account/homePage'] })
      }
    },
  },
  {
    path: '/registration',
    name: REGISTRATION,
    component: () => import('@/views/Account/Registration.vue'),
    meta: {
      guest: true,
    },
  },
]

export default authRoutes
