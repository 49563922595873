import { configure, defineRule } from 'vee-validate'
import { postcodeValidator } from 'postcode-validator'
import phoneHandler from '@/services/PhoneHandler'
import allCountries from '@/assets/files/countries.json'
import {
  required,
  email,
  min,
  max,
  alpha,
  alpha_num,
  numeric,
  between,
  confirmed,
  length,
  is,
  regex,
  alpha_spaces,
  integer,
} from '@vee-validate/rules'
import type { Country } from '@/types/Models/Customer'

// register default rules
const standardRules: any = {
  required,
  email,
  min,
  max,
  alpha,
  alpha_num,
  alpha_spaces,
  numeric,
  between,
  confirmed,
  length,
  is,
  regex,
  integer,
}
Object.keys(standardRules).forEach((rule) => {
  defineRule(rule, standardRules[rule])
})

defineRule('emailPhoneRequired', (value: any) => {
  return value?.length > 0 || 'Email or Phone Number is required'
})

defineRule('password', (value: any) => {
  if (value?.length === 0) {
    return 'Must be a combination of 8 or more uppercase and lowercase letters, numbers and symbols'
  }
  const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,})/
  return (
    strongRegex.test(value) || 'Must be a combination of 8 or more uppercase and lowercase letters, numbers and symbols'
  )
})

defineRule('countryCode', (value: any) => {
  if (!value) return true

  const validValues = [
    ...allCountries.map((country: Country) => country.value),
    ...allCountries.map((country: Country) => country.text),
    ...allCountries.map((country: Country) => `${country.value} - ${country.text}`),
  ]

  return validValues.includes(value) ? true : 'Invalid country code'
})

defineRule('phone', (value: any, [country = 'SE']) => {
  return phoneHandler.validate(value, country) || 'Phone Number is invalid'
})

defineRule('simplePhone', (value: any, country: string[] | null) => {
  return phoneHandler.validateWithoutCountry(value, country ? country.join('') : null) || 'Phone Number is invalid'
})

defineRule('alpha_num_spaces', (value: any) => {
  const strongRegex = new RegExp('^[a-zA-Z0-9\\s]+$')
  return strongRegex.test(value) || 'First name must be correct'
})

defineRule('zip', async (value: any, [code = 'SE']) => {
  const countryCode = !code ? 'SE' : code
  return postcodeValidator(value, countryCode) || 'Zip Code is invalid'
})

defineRule('lengthIn', (value: any, options: any, opt: any) => {
  return options.includes(value.length) || `${opt.name || 'Length'} is invalid`
})

defineRule('min_value', (value: any, minValue: any, opt: any) => {
  return value >= minValue || `${opt.name.replace(/\[.+?]/g, '')} must be ${minValue} or more`
})

defineRule('max_value', (value: any, maxValue: any, opt: any) => {
  return value <= maxValue || `${opt.name.replace(/\[.+?]/g, '')} must be ${maxValue} or less`
})

defineRule('commodityCode', (value: any) => {
  value = String(value)
  return `${value.substring(0, 2)}` !== '00' || 'This value must be a positive number'
})

defineRule('permissible', (value: any) => {
  return value?.length === 0 ? '' : true
})

configure({
  generateMessage: (context) => {
    const messages: any = {
      required: `The field ${context.field.replace(/\[.+?]/g, '')} is required.`,
      emailPhoneRequired: `Email or Phone Number is required`,
      password: `Must be a combination of 8 or more uppercase and lowercase letters, numbers and symbols`,
      phone: `Phone Number is invalid`,
      alpha_num_spaces: `First name must be correct`,
      zip: `Zip Code is invalid`,
      lengthIn: `Zip Code is invalid`,
      min_value: `Zip Code is invalid`,
      commodityCode: `This value must be a positive number`,
      permissible: '',
    }

    return !context?.rule?.name || !messages[context.rule.name]
      ? `The field ${context.field.replace(/\[.+?]/g, '')} is invalid.`
      : messages[context.rule.name]
  },
})
